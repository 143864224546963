import { formatValue, ValueType } from '../utils/formatters';
import './ProgressionTable.scss';

export type ProgressionRow = {
  label: string;
  value: string | number | null | undefined;
  type: ValueType;
};

function getProgressPercentage(row: ProgressionRow) {
  let result = 0;

  if (!row?.value || typeof row?.value !== 'number') {
    return result;
  }

  if (row.type === 'percentage') {
    result = row.value * 100;
  }

  if (result > 0 && result < 2) {
    return 2;
  }

  return result;
}

export default function ProgressionTable({
  title,
  rows,
  subtitle,
  className,
  showOverflowTooltip,
}: Readonly<{
  title: string;
  rows: ProgressionRow[];
  subtitle?: string;
  className?: string;
  showOverflowTooltip?: boolean;
}>) {
  return (
    <section className={`progression-table ${className ?? ''}`}>
      <div className="progression-table__titles">
        <h3>{title}</h3>
        <h3 className="progression-table__titles__subtitle">{subtitle}</h3>
      </div>

      {rows.map((row) => (
        <div className="progression-table__row" key={row?.label}>
          <span
            aria-describedby={
              showOverflowTooltip ? 'row_full_label' : undefined
            }
            className="progression-table__row__label"
          >
            {row.label}
          </span>

          {showOverflowTooltip ? (
            <div role="tooltip" id="row_full_label">
              {row.label}
            </div>
          ) : null}

          <div className="progression-table__row__progression">
            <div
              className="progression-table__row__progression__progress"
              style={{ width: `${getProgressPercentage(row)}%` }}
            />
          </div>
          <span className="progression-table__row__value">
            {formatValue(row?.value, row?.type)}
          </span>
        </div>
      ))}
    </section>
  );
}
