import React from 'react';
import Tippy from '@tippyjs/react';
import classnames from 'classnames';

import './Icon.scss';

export default function UIIcon({
  className,
  icon: parentIcon,
  platformOriginalColors,
  size,
  tooltip,
  tooltipDelay,
  tooltipInteractive,
  tooltipPopperOptions,
  tooltipPosition = 'top',
  tooltipTheme = 'dark',
  type,
}: Readonly<{
  className?: string;
  icon?: string;
  platformOriginalColors?: boolean;
  size?: string;
  tooltip?: React.ReactNode;
  tooltipDelay?: number;
  tooltipInteractive?: boolean;
  tooltipPopperOptions?: Record<string, unknown>;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  tooltipTheme?: 'dark' | 'light';
  type?: string;
}>) {
  const icon = parentIcon ?? type;
  const classNames = classnames('ui-icon', `icon-${icon}`, className, {
    [`ui-icon--${size}`]: size,
    'platform-original-colors': platformOriginalColors,
  });

  if (!tooltip) {
    return <span className={classNames} />;
  }

  return (
    <Tippy
      content={tooltip}
      placement={tooltipPosition}
      popperOptions={tooltipPopperOptions}
      theme={tooltipTheme}
      interactive={tooltipInteractive}
      arrow
      delay={tooltipDelay}
      animation="shift-toward"
      appendTo={document.body}
    >
      <span className={classNames} />
    </Tippy>
  );
}
