/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// @TODO: refactory to remove these eslint-disable rules
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { useEffect, useState, useRef } from 'react';
import useDebouncedPromise from 'utils/useDebouncedPromise';
import uniqBy from 'lodash/uniqBy';
import useDidUpdate from 'utils/useDidUpdate';
import { toArray } from 'utils/array';
import useDeepCompareMemoize from 'utils/useDeepCompareMemoize';
import { errorStream } from 'store/errorHandler';
import cancellablePromise from 'utils/cancellablePromise';
import {
  mapItems,
  inMap,
  hasValue,
  getPopulatedValue,
  getValueKeys,
  normalizeInputValue,
  hasObjects,
} from './utils';
import {
  Filter,
  AsyncSelectorOptions,
  ListRequestPayloadBase,
} from './useAsyncSelectorTypes';

/*
 * @opts
 * filters
 * onBlur
 * onChange
 * onFocus
 * parentFilter
 * parentValue
 * valueKey
 */

function useOptionsHidrator(
  parentValue,
  valueKey,
  itemsMap,
  updateItemsMap,
  load,
) {
  useEffect(() => {
    const valueIds = getValueKeys(parentValue, valueKey);

    if (hasValue(parentValue) && !inMap(itemsMap, valueIds)) {
      if (hasObjects(parentValue)) {
        updateItemsMap(parentValue);
      } else {
        load({ id: toArray(valueIds) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentValue]);
}

function useFilterChangesTracker(
  parentFilter: Filter,
  onFiltersChange: (arg0: Filter) => void,
) {
  const filter: Filter = useDeepCompareMemoize(parentFilter);
  useDidUpdate(() => {
    if (typeof parentFilter !== 'function') {
      onFiltersChange(filter);
    }
  }, [filter]);
}

const nonNull = (id) => id !== -1;
const hasMinQueryLength = (query, queryMinLength) =>
  (query || '').length >= queryMinLength;

const getAllOptions = (options, currentValue, filters, valueKey) =>
  (filters.query ? options : uniqBy([...options, ...currentValue], valueKey));

/* eslint-disable sonarjs/cognitive-complexity */
export default function useAsyncSelector(
  fetchItems,
  {
    limit = 24,
    parentFilter,
    initialFilter,
    parentValue,
    queryMinLength = 0,
    sort,
    valueKey,
    allowNoneOption,
    noneOption,
    forceNoneOption,
    defaultMenuIsOpen,
    delay = 300,
    isDebounced = false,
    loadOnMount,
  }: AsyncSelectorOptions,
) {
  const [emptyOptions] = useState<OptionTypeBase[]>(() =>
    (allowNoneOption ? [noneOption] : []),
  );

  const debouncedFetchItems = useDebouncedPromise(fetchItems, delay);
  const cancellablePromiseRef = useRef(null);
  const isOpenRef = useRef(false);
  const outdatedResultsRef = useRef(false);
  const hasMoreRef = useRef(false);
  const skipRef = useRef(0);
  const parentInitialFilter =
    typeof parentFilter === 'function' ? parentFilter() : parentFilter;
  const [filter, setFilter] = useState(() => ({
    ...initialFilter,
    ...parentInitialFilter,
  }));
  const [itemsMap, setItemsMap] = useState(() =>
    mapItems(parentValue, valueKey, noneOption),
  );
  const [options, setOptions] = useState(emptyOptions);
  const [optionsDidLoad, setOptionsDidLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let baseValue = parentValue;
  if (!baseValue && noneOption && forceNoneOption) {
    baseValue = noneOption;
  }

  if (!baseValue) {
    baseValue = null;
  }

  const valueKeys = getValueKeys(baseValue, valueKey);
  const populatedValue = getPopulatedValue(itemsMap, valueKeys);

  function updateItemsMap(newItems) {
    setItemsMap((currentItemsMap) => ({
      ...currentItemsMap,
      ...mapItems(newItems, valueKey),
    }));
  }

  async function load({ skip, ...nfilter }, debounced) {
    setIsLoading(true);

    skipRef.current = skip || 0;

    const payload: ListRequestPayloadBase = {
      filter: nfilter,
      limit: nfilter.id ? nfilter.id.length : limit,
      skip: skipRef.current,
    };

    if (sort) {
      payload.sort = sort;
    }

    const fetcher = debounced ? debouncedFetchItems : fetchItems;

    let result = null;

    if (cancellablePromiseRef.current) {
      cancellablePromiseRef.current.cancel();
    }

    cancellablePromiseRef.current = cancellablePromise(() => fetcher(payload));
    result = await cancellablePromiseRef.current.promise;

    if (result.cancelled) {
      return null;
    }

    cancellablePromiseRef.current = null;

    if (result.error) {
      errorStream.dispatch({
        type: 'networkError',
        error: result.error,
      });
      setIsLoading(false);
      return null;
    }

    updateItemsMap(result.data);
    setIsLoading(false);

    if (!payload.filter.id && result.meta) {
      hasMoreRef.current = result.meta.total > payload.skip + limit;
    }

    return result;
  }

  async function loadOptions(nfilter, debounced) {
    if (debounced) {
      setOptions(emptyOptions);
    }

    if (!hasMinQueryLength(nfilter.query, queryMinLength)) return;

    const vkeys = toArray(getValueKeys(parentValue, valueKey));
    const idExclude = [...vkeys, ...(nfilter.idExclude || [])];

    const result = await load(
      {
        ...nfilter,
        idExclude: idExclude.filter(nonNull),
      },
      debounced,
    );

    if (result?.data || result?.nodes) {
      const obj = getPopulatedValue(itemsMap, vkeys);
      const allOptions = getAllOptions(
        result.data ?? result.nodes,
        toArray(obj),
        nfilter,
        valueKey,
      ).filter((item) => item[valueKey] !== -1);

      const newOptions = allowNoneOption
        ? [noneOption, ...allOptions]
        : allOptions;

      setOptions(newOptions.filter((o) => Boolean(o)));
      setOptionsDidLoad(true);
    }
  }

  async function loadMore() {
    if (!hasMoreRef.current || isLoading) {
      return;
    }
    const result = await load(
      {
        ...filter,
        skip: skipRef.current + limit,
      },
      true,
    );

    if (!result?.data) {
      return;
    }

    const newOptions = [...options, ...result.data];
    if (allowNoneOption && noneOption) {
      newOptions.unshift(noneOption);
    }
    setOptions(newOptions);
  }

  function onFilterChange(nfilter) {
    const f = typeof nfilter === 'function' ? nfilter(populatedValue) : nfilter;
    const mergedFilters = { ...filter, ...f };
    const parentF =
      typeof parentFilter === 'function'
        ? parentFilter(populatedValue)
        : parentFilter;

    if (!mergedFilters.query && parentF && parentF.query) {
      mergedFilters.query = parentF.query;
    }

    setFilter(mergedFilters);

    if (isOpenRef.current) {
      loadOptions(mergedFilters, true);
    } else {
      setOptions(emptyOptions);
      outdatedResultsRef.current = true;
    }
  }

  function onMenuOpen() {
    if (isOpenRef.current) {
      /* react-select calls onMenuOpen every time we type, see following issue:
       * https://github.com/JedWatson/react-select/issues/3335
       * will open a pull request agains their repo, until there, keep it.
       */
      return;
    }

    isOpenRef.current = true;

    if (!optionsDidLoad || outdatedResultsRef.current) {
      outdatedResultsRef.current = false;
      const parentF =
        typeof parentFilter === 'function'
          ? parentFilter(populatedValue)
          : parentFilter;
      const f = { ...filter, query: undefined, ...parentF };

      if (!f.query && parentF && parentF.query) {
        f.query = parentF.query;
      }

      loadOptions(f, isDebounced);
    }
  }

  function onMenuClose() {
    setOptions([]);
    setOptionsDidLoad(false);
    isOpenRef.current = false;
  }

  function onInputChange(inputText, { action }) {
    const inputValue = normalizeInputValue(inputText, queryMinLength);

    if (inputValue === filter.query) {
      return;
    }

    // The input change is triggered from many different events, but we just care about these two.
    if (!['input-change', 'menu-close'].includes(action)) {
      return;
    }

    if (action === 'menu-close') {
      isOpenRef.current = false;
    }

    onFilterChange({ query: inputValue });
  }

  useOptionsHidrator(parentValue, valueKey, itemsMap, updateItemsMap, load);
  useFilterChangesTracker(parentFilter, onFilterChange);

  useEffect(() => {
    if (defaultMenuIsOpen) {
      onMenuOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadOnMount) {
      const parentF =
        typeof parentFilter === 'function'
          ? parentFilter(populatedValue)
          : parentFilter;
      const f = { ...filter, query: undefined, ...parentF };

      if (!f.query && parentF && parentF.query) {
        f.query = parentF.query;
      }

      loadOptions(f, isDebounced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    filter,
    hasMinLength: hasMinQueryLength(filter.query, queryMinLength),
    hasMore: hasMoreRef.current,
    isLoading,
    loadMore,
    onFilterChange,
    onInputChange,
    onMenuOpen,
    onMenuClose,
    options,
    value: populatedValue,
    imperativeControls: {
      reset: () => {
        setOptions([]);
        setOptionsDidLoad(false);
      },
      updateOptions: (items) => updateItemsMap(items),
    },
  };
}
