import { ValueType } from '@UIComponents/utils/formatters';
import { QmMetrics, MetricResult } from 'components/Production/types';
import {
  AVERAGE_ENGAGEMENT_RATE,
  INSTAGRAM_STATISTICS,
} from 'components/Creator/Productions/Card/Instagram/constants';

export function getBasicStatsRows(metrics: {
  platformMetrics?: QmMetrics | null;
  metricResults?: MetricResult[] | null;
}) {
  return [
    {
      label: 'Followers',
      type: 'wholeNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.statistics?.value
          ?.followers_count,
    },
    {
      label: 'Bot Score',
      type: 'percentage' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.['ig-safe-inference']?.values
          ?.bot_score,
    },
    {
      label: 'Avg Comments',
      type: 'wholeNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.avg_comments,
    },
    {
      label: 'Average Likes',
      type: 'floatNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.avg_likes,
    },
    {
      label: 'Average Views',
      type: 'floatNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.['eureka-ig-views-inference']
          ?.values?.story_view_prediction,
    },
    {
      label: AVERAGE_ENGAGEMENT_RATE,
      type: 'percentage' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.engagement_rate,
    },
    {
      label: 'Average Engagement',
      type: 'wholeNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.avg_engagements,
    },
    {
      label: 'Average days between posts',
      type: 'wholeNumber' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.average_days_between_posts,
    },
    {
      label: 'Post Frequency',
      type: 'string' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.post_frequency,
    },
    {
      label: 'Growth Percent',
      type: 'percentage' as ValueType,
      value:
        metrics?.platformMetrics?.attributes?.[INSTAGRAM_STATISTICS]?.values
          ?.growth_percent,
    },
    {
      label: 'Audience Cluster',
      type: 'link' as ValueType,
      value: 'https://bengroup.domo.com/page/838904074',
    },
  ];
}
