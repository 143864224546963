import { Production } from 'components/Production/types';
import CardMetrics from 'components/Creator/Productions/Card/TikTok/CardMetrics/CardMetrics';
import RecentVideos from 'components/Creator/Productions/Card/TikTok/RecentVideos/RecentVideos';

import './TikTok.scss';

export default function TikTok({
  production,
  trackMissingDemographics,
  showRecentMedia = true,
}: Readonly<{
  production: Production;
  showRecentMedia: boolean;
  trackMissingDemographics: () => void;
}>) {
  return (
    <section className="creator-tiktok-details">
      {showRecentMedia ? <RecentVideos production={production} /> : null}

      <CardMetrics
        production={production}
        trackMissingDemographics={trackMissingDemographics}
      />
    </section>
  );
}
