import gql from 'graphql-tag';

export enum ViewershipType {
  REPORTED = 'reported',
  EXPECTED = 'expected',
}

export default gql`
  query GetManualViewershipsBySource(
    $sourceId: Int!
    $limit: Int
    $skip: Int
    $sort: String
    $type: ViewershipType
  ) {
    manualViewershipsBySource(
      sourceId: $sourceId
      limit: $limit
      skip: $skip
      sort: $sort
      type: $type
    ) {
      id
      watchStationId
      watchSourceId
      watchStation {
        id
        displayName
        country
        image
      }
      type
      provider
      premiereDate
      premiereImpressions
      past30Impressions
      past60Impressions
      past90Impressions
      past365Impressions
      notes
      premiereDetails {
        id
        extraData
        description
        male
        female
        age1to17
        age18to24
        age25to34
        age35to44
        age45to54
        age55to65
        age65plus
        age21plus
        ethnicityWhite
        ethnicityHispanic
        ethnicityBlack
        ethnicityAsian
        ethnicityOther
        incomeUnder20
        incomeUnder50
        income20to49
        income50to74
        income75to99
        income100to149
        income150to199
        income100plus
        income200plus
      }
      past30Details {
        id
        extraData
        description
        male
        female
        age1to17
        age18to24
        age25to34
        age35to44
        age45to54
        age55to65
        age65plus
        age21plus
        ethnicityWhite
        ethnicityHispanic
        ethnicityBlack
        ethnicityAsian
        ethnicityOther
        incomeUnder20
        incomeUnder50
        income20to49
        income50to74
        income75to99
        income100to149
        income150to199
        income100plus
        income200plus
      }
      past60Details {
        id
        extraData
        description
        male
        female
        age1to17
        age18to24
        age25to34
        age35to44
        age45to54
        age55to65
        age65plus
        age21plus
        ethnicityWhite
        ethnicityHispanic
        ethnicityBlack
        ethnicityAsian
        ethnicityOther
        incomeUnder20
        incomeUnder50
        income20to49
        income50to74
        income75to99
        income100to149
        income150to199
        income100plus
        income200plus
      }
      past90Details {
        id
        extraData
        description
        male
        female
        age1to17
        age18to24
        age25to34
        age35to44
        age45to54
        age55to65
        age65plus
        age21plus
        ethnicityWhite
        ethnicityHispanic
        ethnicityBlack
        ethnicityAsian
        ethnicityOther
        incomeUnder20
        incomeUnder50
        income20to49
        income50to74
        income75to99
        income100to149
        income150to199
        income100plus
        income200plus
      }
      past365Details {
        id
        extraData
        description
        male
        female
        age1to17
        age18to24
        age25to34
        age35to44
        age45to54
        age55to65
        age65plus
        age21plus
        ethnicityWhite
        ethnicityHispanic
        ethnicityBlack
        ethnicityAsian
        ethnicityOther
        incomeUnder20
        incomeUnder50
        income20to49
        income50to74
        income75to99
        income100to149
        income150to199
        income100plus
        income200plus
      }
      createdAt
      updatedAt
      createdBy {
        id
        email
      }
    }
  }
`;
