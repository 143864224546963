import {
  ValueType,
  formatFloatNumber,
  formatWholeNumber,
} from '@UIComponents/utils/formatters';
import { Audience, Production, QmMetrics } from 'shared/types/Production';
import MetricType from 'shared/types/MetricType';
import { ProgressionRow } from '@UIComponents/ProgressionTable/ProgressionTable';
import { convertPercentage } from '../../utils/convertPercentage';
import { calculateGenderDemographics } from '../../utils/getGenderDemographics';
import metricPerPlatform from '../../utils/getMetricPerPlatform';

function getAverageViews(production: Production) {
  const averageViews = metricPerPlatform(
    production,
    'averageViews' as MetricType,
    true,
  );

  if (Number.isNaN(Number(averageViews))) {
    return averageViews;
  }

  return formatFloatNumber(Number(averageViews));
}

function getSubscribers(production: Production) {
  const value = metricPerPlatform(production, 'followers' as MetricType, true);

  if (Number.isNaN(Number(value))) {
    return value;
  }

  return formatWholeNumber(Number(value));
}

export function getBasicStatsRows(
  metrics: QmMetrics | undefined | null,
  production: Production,
) {
  const averageViews = getAverageViews(production);

  const aiViews =
    metrics?.attributes?.['youtube-views-inference']?.values?.predicted_views;

  const averageEngagementRate =
    metrics?.attributes?.['youtube-statistics']?.values?.engagement_rate;

  const postFrequency = production?.postFrequency;

  const subscribers = getSubscribers(production);

  return [
    {
      label: 'Subscribers',
      type: 'string' as ValueType,
      value: subscribers,
    },
    {
      label: 'Average Views',
      type: 'string' as ValueType,
      value: averageViews,
    },
    {
      label: 'AI-Predicted Views',
      type: 'floatNumber' as ValueType,
      value: aiViews,
    },
    {
      label: 'Avg. Engagement Rate',
      type: 'percentage' as ValueType,
      value: averageEngagementRate,
    },
    {
      label: 'Post Frequency',
      type: 'string' as ValueType,
      value: postFrequency,
    },
  ];
}

export function getAgeDemographics(
  audience: Audience | null | undefined,
): ProgressionRow[] {
  return [
    {
      label: '13-17',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age13to17),
    },
    {
      label: '18-24',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age18to24),
    },
    {
      label: '25-34',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age25to34),
    },
    {
      label: '35-44',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age35to44),
    },
    {
      label: '45-54',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age45to54),
    },
    {
      label: '55-64',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age55to64),
    },
    {
      label: '65+',
      type: 'percentage' as ValueType,
      value: convertPercentage(audience?.age65plus),
    },
  ];
}

export function getGenderDemographics(audience: Audience | null | undefined) {
  const femalePercent = convertPercentage(audience?.genderFemale);
  const malePercent = convertPercentage(audience?.genderMale);
  const nonBinaryPercent = convertPercentage(audience?.genderNonBinary);

  const genderDemographics = calculateGenderDemographics(
    malePercent ?? 0,
    femalePercent ?? 0,
    nonBinaryPercent ?? 0,
  );

  return [
    {
      label: 'Female',
      type: 'percentage' as ValueType,
      value: genderDemographics?.femalePercent,
    },
    {
      label: 'Male',
      type: 'percentage' as ValueType,
      value: genderDemographics?.malePercent,
    },
    {
      label: 'Other',
      type: 'percentage' as ValueType,
      value: genderDemographics?.nonBinaryPercent,
    },
  ];
}
