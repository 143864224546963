import { Production } from 'components/Production/types';
import Audience from './Audience/Audience';
import BasicStats from './BasicStats/BasicStats';

import './CardMetrics.scss';

export default function CardMetrics({
  production,
  trackMissingDemographics,
}: Readonly<{
  production: Production;
  trackMissingDemographics: () => void;
}>) {
  return (
    <section className="tiktok-card-metrics">
      <BasicStats
        metrics={production?.tiktokCreatorDataInsights}
        production={production}
      />
      <Audience
        metrics={production?.tiktokCreatorDataInsights?.authorizedDataInsights}
        trackMissingDemographics={trackMissingDemographics}
      />
    </section>
  );
}
