import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FormSelector from '../../../Form/Selector/Selector';
import useAsyncSelector from '../../../Form/useAsyncSelector/useAsyncSelector';
import Option from './Option';

const cbn = 'form-station-selector';
const limit = 5;
export const GetStationsByName = gql`
  query SearchStationsByName(
    $search: String!
    $limit: Int = ${limit}
    $skip: Int = 0
  ) {
    stations(search: $search, limit: $limit, skip: $skip) {
      id
      displayName
      type
      country
      image
    }
  }
`;
export type StationProps = {
  id: string;
  name: string;
  type: string;
  country: string;
};

type FormStationSelectorProps = Partial<{
  disabled: boolean;
  isClearable: boolean;
  isMulti: boolean;
  onChange: unknown;
  onFocus: unknown;
  placeholder: string;
  value: StationProps | StationProps[];
  valueKey: string;
  idsOnly: boolean;
}>;

function FormStationSelector({
  disabled,
  isClearable,
  isMulti,
  onChange,
  onFocus,
  placeholder,
  value: parentValue,
  valueKey = 'id',
  idsOnly,
  ...restProps
}: Readonly<FormStationSelectorProps>) {
  const client = useApolloClient();

  const fetchStations = useCallback(
    ({ filter, ...options }: { filter: { query: string } }) =>
      client
        .query({
          query: GetStationsByName,
          variables: {
            search: filter.query,
            limit,
            skip: 0,
            ...options,
          },
        })
        .then(
          (result) => ({
            data: result.data.stations,
          }),
          (error) => ({ error }),
        ),
    [client],
  );
  const {
    hasMinLength,
    hasMore,
    isLoading,
    loadMore,
    onInputChange,
    onMenuOpen,
    onMenuClose,
    options,
    value,
  } = useAsyncSelector(fetchStations, {
    parentValue,
    valueKey,
    queryMinLength: 3,
    limit,
  });

  return (
    <FormSelector
      {...restProps}
      className={cbn}
      labelKey="displayName"
      valueKey={valueKey}
      isMulti={isMulti}
      components={{
        Option,
      }}
      disabled={disabled}
      readOnly={false}
      ignoreOptionFilter
      isClearable={isClearable}
      hasMinLength={hasMinLength}
      hasMore={hasMore}
      idsOnly={idsOnly}
      isLoading={isLoading}
      loadMore={loadMore}
      onChange={onChange}
      onFocus={onFocus}
      onInputChange={onInputChange}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}

export default FormStationSelector;
