import classNames from 'classnames';

import './Loader.scss';

export default function UILoader({
  size = 'medium',
  type = '',
  white = false,
  className = '',
}: Readonly<{
  size?: string;
  type?: string;
  white?: boolean;
  className?: string;
}>) {
  return (
    <div
      className={classNames('ben-loader__wrapper', className, {
        [`ben-loader__wrapper--${type}`]: type,
      })}
    >
      <div
        className={classNames('ben-loader', {
          'ben-loader--white': white,
          [`ben-loader--${type}`]: type,
          [`ben-loader--${size}`]: size,
        })}
      />
    </div>
  );
}
