import FormGroup from 'components/Form/Group/Group';
import FormRow from 'components/Form/Row/Row';
import FormSection from 'components/Form/Section/Section';
import { EarlyAccessFeature } from 'posthog-js';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import { useActiveFeatureFlags, usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

import './EarlyAccess.scss';

function EarlyAccess() {
  const posthog = usePostHog();
  const activeFlags = useActiveFeatureFlags();
  const [features, setFeatures] = useState<EarlyAccessFeature[]>([]);

  useEffect(() => {
    posthog.getEarlyAccessFeatures((feat) => {
      setFeatures(feat);
    }, true);
  }, [posthog]);

  const toggleBeta = (betaKey: EarlyAccessFeature['flagKey']) => {
    const isActive = activeFlags?.includes(betaKey ?? '');
    posthog.updateEarlyAccessFeatureEnrollment(betaKey ?? '', !isActive);
  };

  return (
    <FormSection
      title="Early Access"
      noTableOfContents
      className="profile-early-access"
    >
      {features.length === 0
        ? 'No current experiments running.'
        : features.map((feature) => (
          <FormRow key={feature.flagKey}>
            <FormGroup container>
              <Checkbox
                  className="profile-early-access__checkbox"
                  value={activeFlags?.includes(feature.flagKey ?? '')}
                  onChange={() => toggleBeta(feature.flagKey)}
                >
                {feature.name}
              </Checkbox>
              <p className="profile-early-access__description">
                {feature.description}
              </p>
            </FormGroup>
          </FormRow>
          ))}
    </FormSection>
  );
}

export default EarlyAccess;
