import { Production, TCMCreatorDataInsights } from 'shared/types/Production';
import SimpleMetricsTable from '@UIComponents/SimpleMetricsTable/SimpleMetricsTable';
import { getAuthBasicStatsRows } from 'components/Creator/Productions/Card/TikTok/utils/tableRows';
import UnauthorizedWarning from 'components/Creator/Productions/Card/UnauthorizedWarning/UnauthorizedWarning';

import './BasicStats.scss';

export default function BasicStats({
  metrics,
  production,
}: Readonly<{
  metrics?: TCMCreatorDataInsights;
  production: Production;
}>) {
  return (
    <section className="tiktok-basic-stats-container">
      <h2>Basic Stats</h2>
      {metrics?.authorizedDataInsights !== null ? (
        <SimpleMetricsTable
          title="AUTH"
          rows={getAuthBasicStatsRows(
            metrics?.authorizedDataInsights,
            production,
          )}
        />
      ) : (
        <UnauthorizedWarning />
      )}
    </section>
  );
}
