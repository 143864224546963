import { ProgressionRow } from '@UIComponents/ProgressionTable/ProgressionTable';
import {
  ValueType,
  formatFloatNumber,
  formatWholeNumber,
} from '@UIComponents/utils/formatters';
import formatDate from 'shared/dates/formatDate';
import capitalizeString from 'shared/utils/strings/capitalizeString';
import sortTCMMetricsByProgression from 'shared/utils/productions/TikTok/sortTCMMetricsByProgression';
import {
  TCMAudienceAge,
  TCMAudienceCountry,
  TCMAudienceGender,
  TCMAuthorizedDataInsights,
} from 'shared/types/TikTokMarketPlace';
import MetricType from 'shared/types/MetricType';
import { Production } from 'shared/types/Production';
import metricPerPlatform from '../../utils/getMetricPerPlatform';

export function getAudienceGenderRows(metrics?: TCMAudienceGender[]) {
  return ['MALE', 'FEMALE', 'NONE'].map((gender) => ({
    label: capitalizeString(gender),
    type: 'percentage' as ValueType,
    value: (metrics ?? [])?.find((m) => m.gender === gender)?.percentage,
  }));
}

export function getAudienceAgeRows(metrics?: TCMAudienceAge[]) {
  return ['18-24', '25-34', '35+'].map((gender) => ({
    label: gender,
    type: 'percentage' as ValueType,
    value: (metrics ?? [])?.find((m) => m.age === gender)?.percentage,
  }));
}

export function getAudienceCountryRows(metrics: TCMAudienceCountry[]) {
  if (!metrics?.length) {
    return [];
  }
  const sortedMetrics = sortTCMMetricsByProgression(
    metrics,
  ) as TCMAudienceCountry[];

  return sortedMetrics.map((audienceCountry: TCMAudienceCountry) => ({
    label: audienceCountry?.country,
    type: 'percentage' as ValueType,
    value: audienceCountry?.percentage,
  }));
}

function getMetric(production: Production, metric: string, float?: boolean) {
  const value = metricPerPlatform(production, metric as MetricType, true);

  if (Number.isNaN(Number(value))) {
    return value;
  }

  if (float) return formatFloatNumber(Number(value));

  return formatWholeNumber(Number(value));
}

export function getAuthBasicStatsRows(
  metrics: TCMAuthorizedDataInsights | undefined | null,
  production: Production,
) {
  const basicStatsRows: ProgressionRow[] = [
    {
      label: 'Followers',
      type: 'string' as ValueType,
      value: getMetric(production, 'followers'),
    },
    {
      label: 'Videos',
      type: 'string' as ValueType,
      value: getMetric(production, 'videos'),
    },
    {
      label: 'Likes',
      type: 'string' as ValueType,
      value: getMetric(production, 'likes', true),
    },
    {
      label: 'Average Views',
      type: 'floatNumber' as ValueType,
      value: metrics?.average_views,
    },
    {
      label: 'Engagement Rate',
      type: 'percentage' as ValueType,
      value: metrics?.engagement_rate,
    },
    {
      label: 'Avg. comments per Post',
      type: 'floatNumber' as ValueType,
      value: metrics?.average_comments,
    },
    {
      label: 'Average Likes',
      type: 'floatNumber' as ValueType,
      value: metrics?.average_likes,
    },
    {
      label: 'Average shares',
      type: 'floatNumber' as ValueType,
      value: metrics?.average_shares,
    },
    {
      label: 'Completion Rate',
      type: 'percentage' as ValueType,
      value: metrics?.completion_rate,
    },
    {
      label: 'Followers growth rate',
      type: 'percentage' as ValueType,
      value: metrics?.followers_growth_rate,
    },
  ];

  if (metrics?.creator_rate?.currency && metrics?.creator_rate?.rate) {
    basicStatsRows.push({
      label: 'Starting rate',
      type: 'string' as ValueType,
      value: `${formatFloatNumber(metrics?.creator_rate?.rate)} ${
        metrics?.creator_rate?.currency
      }`,
    });
  }

  if (metrics?.is_on_vacation) {
    const startDate = metrics?.vacation_start_date
      ? new Date(metrics.vacation_start_date)
      : null;

    const endDate = metrics?.vacation_end_date
      ? new Date(metrics.vacation_end_date)
      : null;

    if (
      startDate !== null &&
      endDate !== null &&
      !Number.isNaN(startDate.getTime()) &&
      !Number.isNaN(endDate.getTime())
    ) {
      const startDateString = formatDate(startDate.toString());
      const endDateString = formatDate(endDate.toString());
      const dateInterval = `${startDateString} - ${endDateString}`;

      basicStatsRows.push({
        label: 'On vacation',
        type: 'string' as ValueType,
        value: dateInterval,
      });
    }
  }

  return basicStatsRows;
}
